import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export const Content = () => {
  const data = useStaticQuery(graphql`
    query ContentQuery {
      allContentJson {
        edges {
          node {
            value
          }
        }
      }
    }
  `);

  return (
    <main>
      {data.allContentJson.edges.map(({ node: { value } }, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: value }} />
      ))}
    </main>
  );
};
