import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allSocialJson {
        edges {
          node {
            address
            tooltip
            icon
          }
        }
      }
    }
  `);

  return (
    <footer>
      {data.allSocialJson.edges.map(({ node: { address, icon, tooltip } }) => (
        <a target="_blank" rel="noopener noreferrer" href={address} aria-label={tooltip} key={tooltip}>
          <svg className="social-icon" viewBox="0 0 20 20">
            <path fill="none" d={icon} />
          </svg>
        </a>
      ))}
    </footer>
  );
};
