import React from 'react';
import { Content } from '../components/content';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { SEO } from '../components/seo';
import '../main.scss';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Header />
    <Content />
    <Footer />
  </>
);

export default IndexPage;
