import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Link } from 'gatsby';

export const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <Link to="/">
        <h1>{data.site.siteMetadata.title}</h1>
      </Link>
    </header>
  );
};
